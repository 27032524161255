<template>
  <div style="margin-top: 30px">
    <div class="message-box">
      <div class="title">商品说明书</div>
      <div class="mainer">
        <!-- <div class="message-box-left">
          <el-descriptions :column="2">
            <el-descriptions-item
              :label="item.name"
              width="300px"
              v-for="(item, index) in instruction"
              :key="index"
            >
              <el-input
                v-model="instDate[item.key]"
                :placeholder="`请输入${item.name}`"
              ></el-input>
            </el-descriptions-item>
          </el-descriptions>
        </div> -->
        <div v-for="(item, index) in instruction" :key="index" class="marin">
          <div class="namer">{{ item.name }}</div>
          <div class="texter">
            <el-input
              type="textarea"
              v-model="instDate[item.key]"
              :placeholder="`请输入${item.name}`"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    instruction: {
      type: Array,
      default: () => [],
    }, //商品说明书
    instDate: {
      type: Object,
      default: () => {},
    }, //说明书数据
  },
};
</script>
<style lang='scss' scoped>
.el-descriptions-item__label {
  margin-right: 10px;
  width: 200px !important;
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .mainer {
    color: #000;
    font-size: 14px;
    .marin {
      margin-top: 20px;
      .namer {
        margin-bottom: 5px;
      }
      .texter {
        width: 95%;
      }
    }
  }
}
</style>
