<template>
  <div class="MERCH">
    <div class="merch-card">
      <div class="progress-bar">
        <div :class="timeDATA.status == 1 ? 'progress-step1' : ''">
          <span>1</span>商品信息确认
        </div>
        <div :class="timeDATA.status == 2 ? 'progress-step1' : ''">
          <span>2</span>资料库信息完善
        </div>
      </div>
      <div class="message-box scorr-roll">
        <!-- 未通过显示框 -->
        <!-- <div class="time-box">
        </div> -->
        <div v-if="timeDATA.status == 1">
          <!-- 供应商 -->
          <div>
            <MerchantGood
              :businessInfo="goodsMessage.businessInfo"
              :businessInfoNew="AuditbusinessData.business"
            />
          </div>
          <!-- 基本信息 -->
          <div>
            <BasicMessageGood
              :basicInfo="goodsRedact.basicInfo"
              :picture="goodsMessage.picture"
              :manufacturerInfo="goodsMessage.manufacturerInfo"
              :label="goodsMessage.label"
              :basicinfoData="AuditbusinessData.basicInfo"
              :imgDate="AuditbusinessData.picture"
              :manuData="AuditbusinessData.manufacturerInfo"
              :labData="AuditbusinessData.label"
            />
          </div>
          <!-- 价格/库存 -->
          <div>
            <PriceGood
              :priceAndInventory="goodsMessage.priceAndInventory"
              :priceData="AuditbusinessData.priceAndInventory"
            />
          </div>
          <!-- 销售设置 -->
          <div>
            <SellSetGood
              :saleSetting="goodsMessage.saleSetting"
              :saleData="AuditbusinessData.saleSetting"
            />
          </div>
          <!-- 产品资质 -->
          <div>
            <QualificationGood
              :qualify="goodsMessage.qualify"
              :qualifyData="AuditbusinessData.qualify"
            />
          </div>
        </div>
        <!-- 第二部 -->
        <div v-else-if="timeDATA.status == 2">
          <div class="status2-mess">
            <GoodsBasic
              :basicInfo="goodDisc.basicInfo"
              :manufacturerinfo="goodDisc1"
              :manuData="setAuditbusinessData.basicInfo"
              :classify="goodDisc.classify"
              :classifyArr="classifyArr"
              ref="headerChild"
            />
            <GoodsBasic2
              :basicInfo="goodDisc.basicInfo"
              :manufacturerinfo="goodDisc1"
              :manuData="setAuditbusinessData.basicInfo"
              :classify="goodDisc.classify"
              :imgDate="setAuditbusinessData.imgDate"
              :labData="setAuditbusinessData.label"
              :manufacturers="setAuditbusinessData.manufacturerInfo"
              ref="headerChild2"
            />
            <!-- <GoodsBasic
              :basicInfo="goodDisc.basicInfo"
              :manufacturerinfo="goodDisc1"
              :manuData="setAuditbusinessData.basicInfo"
              :classify="goodDisc.classify"
            /> -->
          </div>

          <!-- <div>
            <GoodsIfy :classify="goodDisc.classify" :manuData="AuditbusinessData.basicInfo" />
          </div> -->
          <div>
            <SpecificationBook
              :instruction="goodsMessage.instruction"
              :instDate="AuditbusinessData.instruction"
            >
            </SpecificationBook>
          </div>
        </div>
        <div v-else-if="timeDATA.status == 3"></div>
      </div>
      <div class="btn-box" v-if="timeDATA.status == 1">
        <div class="go-back" @click="toBack">返回</div>
        <div class="refuse" @click="refuseMessage">审核驳回</div>
        <div class="pass" @click="nextStep(2)">下一步</div>
      </div>
      <div class="btn-box" v-if="timeDATA.status == 2">
        <div class="go-back" @click="toBack">返回</div>
        <div class="last-step" @click="nextStep(1)">上一步</div>
        <div
          class="pass"
          @click="
            decideBarCode(
              setAuditbusinessData.basicInfo,
              AuditbusinessData.content,
              AuditbusinessData.auditContent
            )
          "
        >
          审核通过
        </div>
      </div>
      <div class="btn-box" v-if="timeDATA.status == 3">
        <div class="refuse" @click="toBack">返回</div>
      </div>
    </div>

    <!-- 弹框 -->
    <div>
      <el-dialog title="审核驳回提醒" :visible.sync="isRefuse" width="400px">
        <div class="explain">
          确定驳回当前【申请建档】审核？如果当前商品资质或信息不符合平台要求，请在驳回原因中注明
        </div>
        <div class="reason">
          <span>驳回原因：</span>

          <el-input
            type="textarea"
            :rows="2"
            maxlength="200"
            show-word-limit
            placeholder="请输入内容"
            v-model="AuditMessage.remark"
          >
          </el-input>
        </div>
        <!-- <div>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              maxlength="200"
              v-model="AuditMessage.remark"
              show-word-limit
            >
            </el-input>
          </div> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="isRefuse = false">取 消</el-button>
          <el-button type="primary" @click="notarizeRefuse">驳 回</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- <div>
      <el-dialog title="确认" :visible.sync="ispass" width="600px">
        <div>相关资料信息是否均符合平台要求，请确认是否确认通过？</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="ispass = false">取 消</el-button>
          <el-button type="primary" @click="nextPass">确 定</el-button>
        </span>
      </el-dialog>
    </div> -->
    <!--商品存在弹框  -->
    <div>
      <el-dialog title="提示" :visible.sync="isGoodexist" width="500px">
        <div>
          <el-descriptions>
            <el-descriptions-item
              v-for="item in goodDisc.exist"
              :key="item.key"
              :label="item.name"
              :span="4"
            >
              {{ existData[item.key] }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isGoodexist = false">取 消</el-button>
          <el-button type="primary" @click="nextaffirm">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 商品确认弹框 -->
    <div>
      <el-dialog title="审核通过提醒" :visible.sync="isOk" width="400px">
        <div class="explain">
          确定通过当前【申请建档】审核？请您仔细核对商品信息，以保证当前商品资质信息均符合要求且填写无误。
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isOk = false">取 消</el-button>
          <el-button
            type="primary"
            @click="
              passNext(
                setAuditbusinessData.basicInfo,
                AuditbusinessData.instruction,
                AuditbusinessData.auditContent
              )
            "
            >审核通过</el-button
          >
        </span>
      </el-dialog>
      <!-- <el-dialog title="确认弹框" :visible.sync="isOk" width="600px">
        <span>相关资料信息是否均符合平台要求，请再次确认</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isOk = false">取 消</el-button>
          <el-button type="primary" @click="afTk">确 定</el-button>
        </span>
      </el-dialog> -->
    </div>
    <el-dialog title="ERP编码为空" :visible.sync="isERP" width="400px">
      <div class="explain">
        ERP编码为空，将会影响订单传输ERP，是否确认继续审核？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isERP = false">取 消</el-button>
        <el-button type="primary" @click="affirmERP()">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SpecificationBook from "../../audit/merchAudit/merchMessage/comp/specificationBook.vue"; //说明书
import GoodsBasic from "../../audit/merchAudit/merchMessage/comp/goodsBasic.vue";
import GoodsBasic2 from "../../audit/merchAudit/merchMessage/comp/goodsBasic2.vue";
import GoodsIfy from "../../audit/merchAudit/merchMessage/comp/goodsIfy.vue";
import MerchantGood from "@/components/ordinaryGoods/merchant.vue"; //供应商
import BasicMessageGood from "@/components/ordinaryGoods/basicMessage.vue"; //基本信息
import ExplainGood from "@/components/ordinaryGoods/explain.vue"; //商品说明书
import SellSetGood from "@/components/ordinaryGoods/sellSet.vue"; //销售设置
import PriceGood from "@/components/ordinaryGoods/price.vue"; //价格/库存
import QualificationGood from "@/components/ordinaryGoods/qualification.vue"; //产品资质
import NotPassGood from "../../audit/merchAudit/merchMessage/comp/notPass.vue"; //产品资质
import { createNamespacedHelpers } from "vuex";
import { json } from "body-parser";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      activeName: 0,
      // 引入详情json
      goodsMessage: require("../../../assets/json/goodsAntd.json"),
      goodsRedact: require("@/assets/json/goodRedact.json"),
      //
      goodDisc: require("@/assets/json/goodify.json"), //导入配置json

      goodDisc1: {},
      AuditMessage: {
        id: this.$route.query.id,
        remark: "",
        status: 2,
      },
      isRefuse: false, //孔氏拒绝弹框
      ispass: false, //通过拒绝类型
      isGoodexist: false, //商品是否存在
      existData: {},
      timeDATA: {
        status: 1,
      },
      isOk: false,
      setAuditbusinessData: {},
      isFirst: true,
      classifyArr: [[]],
      isERP: false,
    };
  },
  components: {
    MerchantGood, //供应商
    BasicMessageGood, //基本信息
    ExplainGood, //商品说明书
    SellSetGood, //销售设置
    PriceGood, //价格/库存
    QualificationGood, //产品资质
    NotPassGood, //未通过
    GoodsBasic,
    GoodsBasic2,
    GoodsIfy,
    SpecificationBook,
  },
  computed: {
    ...mapState(["AuditbusinessData"]),
  },
  created() {
    if (this.$route.query.id) {
      this.getpostgoodsAuditbusinessDetail();
      this.GetTime();
    }
  },
  methods: {
    ...mapActions([
      "postgoodsAuditbusinessDetail",
      "postgoodsAudit",
      "getgoodsAudittimeLine",
      "postgoodsAuditsaveTimeLine",
      "postgoodsCheck",
      "gethasGoodsByBarCode",
      "getgoodsHasGoodsByBarCode",
    ]),
    affirmERP() {
      this.isERP = false;
      this.timeDATA.status = 2;
    },
    nextStep(val) {
      if (this.isFirst && val == 2) {
        this.isFirst = false;
        this.setAuditbusinessData = JSON.parse(
          JSON.stringify(this.AuditbusinessData)
        );
        if (this.setAuditbusinessData.basicInfo.businessCategoryId == 0) {
          this.setAuditbusinessData.basicInfo.businessCategoryId = "";
        }
        this.setAuditbusinessData.basicInfo.all_goods_category = [[]];
        this.setAuditbusinessData.imgDate = [];
        this.setAuditbusinessData.picture.forEach((item) => {
          this.setAuditbusinessData.imgDate.push(item.url);
        });
        if (this.AuditbusinessData.basicInfo.uniqueId == "") {
          this.isERP = true;
        } else {
          this.timeDATA.status = val;
        }
      }
      if (!this.isFirst && val == 2) {
        if (this.AuditbusinessData.basicInfo.uniqueId == "") {
          this.isERP = true;
        } else {
          this.timeDATA.status = val;
        }
      }
      // if(!this.isFirst && val == 2){
      //   this.$confirm(
      //       "ERP编码为空，将会影响订单传输ERP，是否确认继续审核？"
      //     ).then((event) => {
      //       this.timeDATA.status = val;
      //     });
      // }
      if (val == 1) {
        this.classifyArr = this.$refs.headerChild.newArr;
        this.timeDATA.status = val;
      }
    },
    async getpostgoodsAuditbusinessDetail() {
      let data = await this.postgoodsAuditbusinessDetail({
        id: this.$route.query.id,
      });
      this.goodDisc1 = data.data.manufacturerinfo;
    },
    // 商品存在确认弹框
    nextaffirm() {
      this.isGoodexist = false;
      this.isOk = true;
    },
    // 确认弹框
    async afTk() {
      this.AuditMessage.platformGoodsInfoId =
        this.existData.platformGoodsInfoId;

      let data = await this.postgoodsAudit(this.AuditMessage);
      //console.log(data);
      if (data.code == "200") {
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    //提交下一步
    async passNext(basic, goodbook, antudcontent) {
      console.log(goodbook);
      let that = this;
      basic.all_goods_category = [...that.$refs.headerChild.newArr];
      let newArr = this.$refs.headerChild.newArr;
      // console.log(123,this.$refs.headerChild2.imageList)
      basic.pictures = [];
      this.$refs.headerChild2.imageList.forEach((item) => {
        basic.pictures.push(item.url);
      });
      // return

      // if (!basic.all_goods_category || basic.all_goods_category.length == 1) {
      //   this.$message({
      //     message: "请选择商品分类",
      //     type: "warning",
      //   });
      //   return;
      // }
      // let all_goods_category = basic.all_goods_category;
      // basic.all_goods_category = [];
      // basic.all_goods_category.push(all_goods_category);
      basic.manufacturer =
        this.setAuditbusinessData.manufacturerInfo.manufacturer;
      basic.listingLicenseHolder =
        this.setAuditbusinessData.manufacturerInfo.listingLicenseHolder;
      basic.manufacturerReferred =
        this.setAuditbusinessData.manufacturerInfo.manufacturerReferred;
      basic.productionAddress =
        this.setAuditbusinessData.manufacturerInfo.productionAddress;
      basic.overTheCounter = this.setAuditbusinessData.label.overTheCounter;
      basic.isCollect = this.setAuditbusinessData.label.isCollect;

      // if (!basic.businessCategoryId) {
      //   this.$message({
      //     message: "您还没有选择经营类别，请选择后重试。",
      //     type: "warning",
      //   });
      //   return;
      // }
      if (basic.all_goods_category[0].length == 0) {
        this.$message({
          message: "您还没有设置商品分类，请选择后重试。",
          type: "warning",
        });
        return;
      }
      if (!basic.commonName || basic.commonName == "") {
        this.$message({
          message: "您还没有填写通用名，填写后重试。",
          type: "warning",
        });
        return;
      }
      if (!basic.goodsName || basic.goodsName == "") {
        this.$message({
          message: "您还没有填写商品名称，填写后重试。",
          type: "warning",
        });
        return;
      }
      if (!basic.specification || basic.specification == "") {
        this.$message({
          message: "您还没有填写商品规格，填写后重试。",
          type: "warning",
        });
        return;
      }
      if (!basic.dosageForm || basic.dosageForm == "") {
        this.$message({
          message: "您还没有填写剂型，填写后重试。",
          type: "warning",
        });
        return;
      }
      // if (
      //   (basic.businessCategoryId == 14 ||
      //     basic.businessCategoryId == 15 ||
      //     basic.businessCategoryId == 16 ||
      //     basic.businessCategoryId == 17) &&
      //   (!basic.placeOrigin || basic.placeOrigin == "")
      // ) {
      //   this.$message({
      //     message: "您还没有填写产地，填写后重试。",
      //     type: "warning",
      //   });
      //   return;
      // }
      if (
        (basic.businessCategoryId == 14 ||
          basic.businessCategoryId == 15 ||
          basic.businessCategoryId == 16 ||
          basic.businessCategoryId == 17) &&
        (!basic.executionStandard || basic.executionStandard == "")
      ) {
        this.$message({
          message: "您还没有填写执行标准，填写后重试。",
          type: "warning",
        });
        return;
      }
      if (!basic.manufacturer || basic.manufacturer == "") {
        this.$message({
          message: "您还没有填写生产厂家，填写后重试。",
          type: "warning",
        });
        return;
      }
      // if (!basic.manufacturerReferred || basic.manufacturerReferred == "") {
      //   this.$message({
      //     message: "您还没有填写厂家简称，填写后重试。",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (!basic.productionAddress || basic.productionAddress == "") {
      //   this.$message({
      //     message: "您还没有填写生产地址，填写后重试。",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (!basic.listingLicenseHolder || basic.listingLicenseHolder == "") {
      //   this.$message({
      //     message: "您还没有填写上市许可持有人信息，填写后重试。",
      //     type: "warning",
      //   });
      //   return;
      // }
      if (!basic.barCode) {
        basic.barCode = "";
      }
      if (basic.businessCategoryId == "") {
        basic.businessCategoryId = 0;
      }
      // return;
      let operatorId = sessionStorage.getItem("admin_id");
      let id = this.$route.query.id;
      let pramas = {
        basicinfo: basic,
        content: goodbook,
        audit_content: antudcontent,
        operatorId,
        id,
      }
      pramas = Object.assign(pramas,this.AuditMessage)
      let data = await this.postgoodsAudit(pramas);
      if (data.code == 200) {
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      return;
      // let data = await this.postgoodsCheck();
      // if (data.code == "000000") {
        // this.existData.platformGoodsInfoId = data.content;
        // this.afTk();
        // this.GetTime();
        // this.$router.push({ path: "/goodsCheck" });
        // this.isOk = false;
        // this.$message({
        //   message: data.message,
        //   type: "success",
        // });
      // } else {
      //   this.$message({
      //     message: data.message,
      //     type: "warning",
      //   });
      // }
      // let newObj = {
      //   basicinfo: basic,
      //   content: goodbook,
      // };
      // if (data.code == 200) {
        // if (data.data.is_exist) {
        //   this.existData.platformGoodsInfoId =
        //     data.data.platformGoodsInfoId;
        //   this.isGoodexist = true;
        //   this.existData = data.data;
        // } else {
        //   this.isOk = true;
        //   this.existData.platformGoodsInfoId =
        //     data.data.platformGoodsInfoId;
        // }
      // } else {
      //   this.$message({
      //     message: data.message,
      //     type: "warning",
      //   });
      // }
    },
    // 确认通过
    async decideBarCode(basic, goodbook, antudcontent) {
      let barCode = "";
      console.log(basic);
      // return
      if (basic.barCode != undefined) {
        barCode = basic.barCode;
      }
      // if (!basic.barCode||) {
      //   console.log(1231,basic.barCode)
      //   barCode=basic.barCode
      // }
      let data = await this.getgoodsHasGoodsByBarCode({ barCode });
      if (data.code == "000000") {
        if (data.content.isExist) {
          this.existData.platformGoodsInfoId = data.content.platformGoodsInfoId;
          this.isGoodexist = true;
          this.existData = data.content;
        } else {
          this.isOk = true;
          this.existData.platformGoodsInfoId = data.content.platformGoodsInfoId;
        }
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 获取时间
    async GetTime() {
      // let data = await this.getgoodsAudittimeLine({ id: this.$route.query.id });
      // this.timeDATA = data.data;
    },
    //  返回
    toBack() {
      this.$router.push({ path: "/goodsCheck" });
    },
    // 拒绝弹框
    refuseMessage() {
      this.isRefuse = true;
    },
    // 通过弹框
    passMessage() {
      this.ispass = true;
    },
    // 通过
    async nextPass() {
      // this.passNext(basic, goodbook, antudcontent)
      let data = await this.postgoodsAuditsaveTimeLine({
        id: this.$route.query.id,
        time_line: "2",
      });
      if (data.code == 200) {
        this.$router.push({ path: "/goodsCheck" });
        this.GetTime();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      this.ispass = false;
    },
    // 确认拒绝
    async notarizeRefuse() {
      this.AuditMessage.status = 3;
      if (!this.AuditMessage.remark || this.AuditMessage == "") {
        this.$message({
          message: "您还没有填写驳回原因，请填写后重试。",
          type: "warning",
        });
        return;
      }
      let data = await this.postgoodsAudit(this.AuditMessage);
      if (data.code == "200") {
        this.isRefuse = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 供应商审核
    goodAudit() {
      this.AuditMessage = {
        id: this.$route.query.id,
      };
    },
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    //
    toMessage() {
      this.$router.push({ path: "/cloudGoodMessage" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 70vh;
      padding: 10px;
      .time-box {
        width: 100%;
        height: 100px;
        background: rebeccapurple;
      }
    }
  }
  .btn-box {
    margin-top: 1%;
    width: 100%;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      font-weight: normal;
      line-height: 32px;
      cursor: pointer;
    }
    .go-back {
      width: 88px;
      color: rgba($color: #1c1f23, $alpha: 0.6);
      border: 1px solid #d4d7d9;
    }
    .refuse {
      width: 116px;
      background: #f6b142;
      color: #fff;
      margin: 0 20px;
    }
    .last-step {
      width: 91px;
      margin: 0 20px;
      border: 1px solid #06b7ae;
      color: #06b7ae;
    }
    .pass {
      width: 116px;
      background: #00bfbf;
      color: #ffffff;
    }
  }
}
.status2-mess {
  display: flex;
  > div {
    flex: 1;
  }
}
.progress-bar {
  padding: 10px 0 20px;
  display: flex;
  justify-content: center;
  > div {
    margin: 0 100px;
    font-size: 20px;
    span {
      display: inline-block;
      width: 20px;
      line-height: 20px;
      text-align: center;
      height: 20px;
      border-radius: 20px;
      border: 1px solid #999;
      font-size: 14px;
      box-sizing: border-box;
      margin-right: 5px;
    }
  }
  .progress-step1 {
    color: #00bfbf;
    span {
      border: 1px solid #00bfbf;
      background-color: #00bfbf;
      color: #fff;
    }
  }
}
.explain {
  line-height: 24px;
}
.reason {
  display: flex;
  line-height: 24px;
  margin-top: 10px;
  span {
    width: 100px;
  }
}
::v-deep .el-dialog__body {
  padding: 20px;
  padding-top: 0;
}
</style>
