<template>
  <div>
    <div class="message-box">
      <div class="title">商品分类</div>
      <div class="message-box-box">
        <div class="message-box-left">
          <el-descriptions :column="2">
            <el-descriptions-item
              :label="item.name"
              width="200px"
              v-for="(item, index) in classify"
              :key="index + ''"
            >
              <template v-if="item.select || item.radio">
                <!--  -->
                <template v-if="item.select">
                 
                  <el-select
                    v-model="manuData[item.key]"
                    :placeholder="`请输入${item.name}`"
                  >
                    <el-option
                      v-for="elem in BasissCopeData[item.selKey]"
                      :key="elem.id"
                      :label="elem[item.val]"
                      :value="elem.id"
                    >
                    </el-option>
                  </el-select>
                </template>
                <!--  -->
                <template v-if="item.radio">
                  <el-radio
                    v-for="v in item.padioCheck"
                    :key="v.val"
                    v-model="manuData[item.key]"
                    :label="v.val"
                  ></el-radio>
                </template>
              </template>
              <!--  -->
              <template v-else-if="item.Cascader">
                <div>
                  <el-cascader
                    v-model="manuData[item.key]"
                    :options="BasissCopeData[item.selKey]"
                    :props="propsData"
                    clearable
                  ></el-cascader>
                </div>
              </template>
              <template v-else>
                <el-input
                  v-model="manuData[item.key]"
                  :placeholder="`请输入${item.name}`"
                ></el-input>
              </template>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex");
// /
export default {
  props: {
    classify: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    picture: {
      type: Array,
      default: () => {
        return [];
      },
    }, //数据商品基本
    manuData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      options: [],
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        //级联选择器配置项
      },
    };
  },
  computed: {
    ...commonIndex.mapState(["BasissCopeData"]),
  },
};
</script>
<style lang='scss' scoped>
.el-descriptions-item__label {
  margin-right: 10px;
  width: 200px !important;
  display: flex;
  align-items: center;
  justify-content: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    width: 120px;
    margin-top: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00bfbf;
    color: #fff;
    border-radius: 4px;
  }
  .message-box-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
    border: 1px solid #797979;
    border-radius: 4px;
  }
  .message-box-right {
    width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        border: 1px solid #797979;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;
      .vender-message {
        padding-top: 10px;
        border: 1px solid #797979;
        padding-left: 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
    }
    .label-message {
      padding: 10px;
      width: 100%;
      border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
</style>
