<template>
  <div>
    <div class="message-box">
      <div >
        <div style="width: 100%;margin:0px 20px 20px 20px">
          <div>
            <div class="title" style="margin-bottom: 10px">
              商品照片
              <span class="hint" v-if="$route.query.steured=='false'"
                >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
              >
            </div>
            <div class="goods-img">
              <template v-if="$route.query.type == 3">
                <el-image
                  v-for="(item, index) in imgDate"
                  :key="index"
                  style="width: 70px; height: 70px"
                  :src="item"
                  :preview-src-list="[item]"
                ></el-image>
              </template>
              <template v-else>
                <div class="up-box-bas">
                  <el-upload
                    :action="Actions"
                    list-type="picture-card"
                    :limit="6"
                    :headers="token"
                    :file-list="imageList"
                    :before-upload="beforeAvatarUpload"
                    :data="{ type: '1' }"
                    :on-success="successUpImg"
                  >
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{ file }">
                      <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                      />
                      <span class="el-upload-list__item-actions">
                        <span
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                        >
                          <i class="el-icon-delete" title="删除" />
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </div>
              </template>
            </div>
          </div>
          <div class="title">生产厂家信息</div>
            <div class="message-box-box" style="margin-left: -50px">
              <div class="message-box-left">
                <div>
                  <div class="basicInfo-box">
                    <div>
                      <template>
                        <span class="lable-name"><span class="required">*</span>生产厂家</span>
                        <el-input
                          class="basicInfo-input"
                          v-model="manufacturers.manufacturer"
                          placeholder="请输入生产厂家"
                        >
                        </el-input>
                      </template>
                    </div>
                    <div>
                      <template>
                        <span class="lable-name">厂家简称</span>
                        <el-input
                          class="basicInfo-input"
                          v-model="manufacturers.manufacturerReferred"
                          placeholder="请输入厂家简称"
                        >
                        </el-input>
                      </template>
                    </div>
                    <div>
                      <template>
                        <span class="lable-name">生产地址</span>
                        <el-input
                          class="basicInfo-input"
                          v-model="manufacturers.productionAddress"
                          placeholder="请输入生产地址"
                        >
                        </el-input>
                      </template>
                    </div>
                    <div>
                      <template>
                        <span class="lable-name">上市许可持有人</span>
                        <el-input
                          class="basicInfo-input"
                          v-model="manufacturers.listingLicenseHolder"
                          placeholder="请输入上市许可持有人"
                        >
                        </el-input>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div style="margin-top: 20px">
            <div class="title">
              标签信息
              <span class="hint" v-if="$route.query.steured=='false'"
                >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
              >
            </div>
            <div class="message-box-box">
              <div class="message-box-left">
                <div>
                  <div class="basicInfo-box">
                    <div>
                      <!-- {{labData[item.key]}} -->
                      <span class="radio-labe">是否OTC</span>  
                      <el-radio v-model="labData.overTheCounter" label="1"
                        >是</el-radio
                      >
                      <el-radio v-model="labData.overTheCounter" label="0"
                        >否</el-radio
                      >
                    </div>
                    <div>
                      <!-- {{labData[item.key]}} -->
                      <span class="radio-labe">是否集采</span>  
                      <el-radio v-model="labData.isCollect" label="1"
                        >是</el-radio
                      >
                      <el-radio v-model="labData.isCollect" label="0"
                        >否</el-radio
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="flex: 1">
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex");
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
import { getToken } from "@/utils/auth";
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    classify: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    manufacturerinfo: {
      type: Object,
      default: () => {
        return {};
      },
    }, //商品基本信息
    imgDate: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品图片
    // picture: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // }, //数据商品基本
    manuData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    labData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    manufacturers: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      Actions: wholeUrl + "/basis/upload",
      token: { token: getToken() },
      imageList: [],
      options: [],
      zz: /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        multiple: true,
        //级联选择器配置项
      },
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  computed: {
    ...commonIndex.mapState(["BasissCopeData"]),
  },
  beforeMount() {
  },
  mounted() {
    this.$nextTick(() => {
      this.readImg();
    });
  },
  methods: {
    // 删除上传的图片
    handleRemove(data) {
      //console.log(data);
      this.imageList = this.imageList.filter((item) => {
        return item.url != data.url;
      });
      this.$emit("removeImg", data.url);
    },
    async beforeAvatarUpload(file) {
      if (file.size / 1024 / 1024 > 50)
        return this.$message.error("上传头像图片大小不能超过 50MB!");
      return await compressImage([file]);
    },
    readImg() {
      this.imgDate.forEach((item) => {
        this.imageList.push({ url: item });
      });
    },
    // 成功数据
    successUpImg(data) {
      if (data.code == 200) {
        this.imageList.push({
          url: data.data.full_url,
        });
        this.imgDate.push(data.data.full_url);
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      //
    },
    //删除分类
    // removeClassify(key, index) {
    //   // this.manuData[key].splice(idnex, 1);
    //   this.manuData.all_goods_category.splice(index, 1);;
    // },
    // 添加分类
    // AddClassify(key) {
    //   if(this.manuData.all_goods_category.length<10){
    //     console.log(123132)
    //     let arr=[...this.manuData.all_goods_category]
    //     arr.push([]);
    //     this.manuData.all_goods_category=[...arr]
    //     console.log(123132,this.manuData.all_goods_category)
    //   }
    // },
  },
};
</script>
<style lang='scss' scoped>
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang='scss' scoped>
.el-descriptions-item__label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  // display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // margin-top: 30px;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .message-box-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
  }
  .message-box-right {
    width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        border: 1px solid #797979;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;
      .vender-message {
        padding-top: 10px;
        border: 1px solid #797979;
        padding-left: 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
    }
    .label-message {
      padding: 10px;
      width: 100%;
      border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
.basicInfo-box {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  font-size: 14px;
  > div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .basicInfo-input {
      width: 220px;
    }
  }
}
.lable-name {
  display: inline-block;
  width: 107px;
  text-align: end;
  margin-right: 10px;
}
.mt10 {
  margin-top: 10px;
}
.hint {
  color: #f00;
  font-size: 12px;
  font-weight: normal;
}
.required {
  color: #F34444;
  font-size: 14px;
}

.radio-labe{
  margin-right: 20px;
}
</style>
