<template>
  <div>
    <div class="message-box">
      <div class="title">基本信息</div>
      <div class="message-box-box">
        <div class="message-box-left">
          <div>
            <div
              width="200px"
              v-for="(item, index) in classify"
              :key="index + ''"
            >
              <!--  -->
              <template v-if="item.select">
                <span class="lable-name"
                  >{{ item.name }}</span
                >
                <el-select
                clearable
                  v-model="manuData[item.key]"
                  :placeholder="`请输入${item.name}`"
                >
                  <el-option
                    v-for="elem in BasissCopeData[item.selKey]"
                    :key="elem.id"
                    :label="elem[item.val]"
                    :value="elem.id"
                  >
                  </el-option>
                </el-select>
              </template>
              <!--  -->
              <!--  -->
              <template v-else-if="item.Cascader">
                <!-- <div class="mt10">
                  <span class="lable-name">{{item.name}}</span>
                  <el-cascader
                    v-model="manuData[item.key]"
                    :options="BasissCopeData[item.selKey]"
                    :props="propsData"
                    collapse-tags
                    clearable
                  ></el-cascader>
                </div> -->
                <div class="Cascader">
                  <span class="lable-name"
                    ><span class="required">*</span>{{ item.name }}</span
                  >

                  <div>
                    <div v-for="(val, i) in newArr" :key="i">
                      <el-cascader
                        class="category"
                        v-model="newArr[i]"
                        :options="BasissCopeData.Newgoods_categoryList"
                        :props="propsData"
                        :placeholder="`请输入${item.name}`"
                        clearable
                      ></el-cascader>
                      <span
                        class="exceed_color font-size-12"
                        @click="AddClassify(item.selKey)"
                        v-if="i == newArr.length - 1"
                        >添加</span
                      >
                      <span
                        @click="removeClassify(item.selKey, i)"
                        v-if="i != 0"
                        class="exceed_color font-size-12"
                        >删除</span
                      >
                      
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="basicInfo-box">
              <div v-for="(item, index) in basicInfo" :key="index + ''">
                <!-- <template v-if="item.isfalang">
                {{item.name}}
                <el-input
                  @change="
                    zz.test(manuData[item.key]) ? (manuData[item.key] = '') : ''
                  "
                  v-model="manuData[item.key]"
                  :placeholder="`请输入${item.name}`"
                ></el-input>
              </template> -->
                <!--  -->
                <template v-if="!item.isfalang">
                  <span class="lable-name"
                    ><span class="required" v-if="item.required">*</span
                    >{{ item.name }}</span
                  >
                  <el-input
                    class="basicInfo-input"
                    v-model="manuData[item.key]"
                    :placeholder="`请输入${item.name}`"
                  >
                  </el-input>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex");
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    classify: {
      type: Array,
      default: () => {
        return [];
      },
    }, //商品基本信息
    manufacturerinfo: {
      type: Object,
      default: () => {
        return {};
      },
    }, //商品基本信息
    picture: {
      type: Array,
      default: () => {
        return [];
      },
    }, //数据商品基本
    manuData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    classifyArr: {
      type: Array,
      default: () => {
        return [];
      },
    }, //数据商品基本
  },
  data() {
    return {
      options: [],
      zz: /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
        // disabled:'forbidden'
        disabled:!'status_',
        checkStrictly: true,
        // multiple: true
        //级联选择器配置项
      },
      newArr: [[]],
    };
  },
  computed: {
    ...commonIndex.mapState(["BasissCopeData"]),
  },
  beforeMount() {
  },
  created() {
    this.newArr = this.classifyArr;
  },
  methods: {
    // 删除分类
    removeClassify(key, index) {
      // this.manuData[key].splice(idnex, 1);

      this.newArr.splice(index, 1);
    },
    // 添加分类
    AddClassify(key) {
      // this.newArr.push([])
      // return
      if (this.newArr.length < 10) {
        this.newArr.push([]);
        // console.log(123132)
        // let arr=[...this.manuData.all_goods_category]
        // arr.push([]);
        // this.manuData.all_goods_category.$set()
        // this.$nextTick((_) => {
        //   // this.manuData.all_goods_category.push([])
        //   // console.log('444444444444');
        //   // // this.manuData.all_goods_category,this.manuData.all_goods_category.length,[]
        //   this.$set(
        //     this.manuData.all_goods_category,
        //     this.manuData.all_goods_category.length,
        //     []
        //   );
        // });
        // this.$set(this.manuData.all_goods_category,this.manuData.all_goods_category.length,[])
        // this.manuData.all_goods_category=[...arr]
        // console.log(123132, this.manuData.all_goods_category);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.el-descriptions-item__label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  // display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // padding: 10px;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .message-box-box {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .message-box-left {
    margin-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
  }
  .message-box-right {
    width: 48%;
    .goods-img-box {
      width: 100%;
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        border: 1px solid #797979;
        padding-left: 10px;
        margin-top: 10px;
      }
    }
    .vender-message-box {
      width: 100%;
      .vender-message {
        padding-top: 10px;
        border: 1px solid #797979;
        padding-left: 10px;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
      }
    }
    .label-message {
      padding: 10px;
      width: 100%;
      border: 1px solid #797979;
      padding-left: 10px;
      margin-top: 10px;
      border-radius: 4px;
    }
  }
}
.basicInfo-box {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  font-size: 14px;
  > div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .basicInfo-input {
      width: 220px;
    }
  }
}
.required {
  color: #F34444;
  font-size: 14px;
}
.lable-name {
  display: inline-block;
  width: 90px;
  text-align: end;
  margin-right: 10px;
  font-size: 14px;
}
.mt10 {
  margin-top: 10px;
}
.Cascader {
  display: flex;
  span {
    margin-top: 10px;
    line-height: 40px;
  }
  > div {
    > div {
      margin-top: 10px;
    }
  }
}
</style>
